<script>
import { layoutComputed } from "@/state/helpers";
import { emailAccountComputed, emailAccountMethods } from "../state/helpers/eaccount";
import { communicationChannelMethods, communicationChannelComputed } from "../state/helpers/communicationchannel";
import { emailNotificationMethods } from "../state/helpers/emailnotification";
import { authUserComputed, authUserMethods } from "../state/helpers/authuser";
import { inboxMethods } from "../state/helpers/inbox";

export default {
  computed: {
    ...layoutComputed,
    ...authUserComputed,
    ...emailAccountComputed,
    ...communicationChannelComputed,
    channels: {
      get: function () {
        return this.getAllChannels;
      },
      set: function (value) {
        return value
      }
    },
    user: {
      get: function () {
        return this.getUser;
      },
    }
  },
  components: {},
  data() {
    return {
      accounts: null,
      account_title: "Email Accounts",
      current_language: "en",
    };
  },

  methods: {
    ...authUserMethods,
    ...emailAccountMethods,
    ...communicationChannelMethods,
    ...emailNotificationMethods,
    ...inboxMethods,
    loadChannels() {
      return this.allChannel({}).then((response) => {
        if (response) {
          this.channels = response;
          
        }
      });
    },
    loadNotification() {
      return this.allEmailNotification({}).then((response) => {
        return response;
      })
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },

    toggleMenu() {
      let element = document.getElementById("topnav-menu-content");
      element.classList.toggle("show");
    },

    switchChannel(data) {
      if (data.channel_type_id === 1) {
        localStorage.setItem("email_account_id", data.email_account.id);
        localStorage.setItem("communication_channel_id", data.id);
        let contact_data = {
          search_term: "",
          last_contact_id: 0,
          account_id: data.email_account.id,
          limit: 100
        }
        this.emptyInbox();
        this.allEmailContact(contact_data)
        this.showChannel(data.id);

        if (this.$route.path != '/email-channel') {
          this.$router.push('/email-channel').catch(() => { });
        }

      }

      if (data.channel_type_id === 2) {
        this.$router.push('/sms-channel').catch(() => { })
      }

      if (data.channel_type_id === 3) {
        this.$router.push('/chat-channel').catch(() => { })
      }
    },

    logout() {
      localStorage.removeItem('connectxone_token');
      this.$router.push('/login').catch(() => { });

    },

    loadUser() {
      return this.getUserData();
    }
  },
  mounted() {
    this.loadUser();
    this.loadChannels();
    // this.loadNotification();
  },
  watch: {

  },
};
</script>

<template>
  <div class="ctx-top-bar-vue">
    <div class="container-fluid-view">
      <div class="ctx-navbar-view-box">
        <div class="logo-view">
          <h4>ConnectXone</h4>
        </div>
        <div class="channel-view">

          <div class="icon-view" v-for="(channel, index) in channels" :key="index">
            <!-- <div class="notification-view"
              v-if="channel.email_account.email_notification && channel.email_account.email_notification.length !== 0">
              {{ channel.email_account.email_notification.length }}</div> -->
            <i :class="channel.icon" :style="{ 'color': channel.icon_color + '!important' }" id="tooltip-top"
              @click="switchChannel(channel)"></i>
            <div class="ctx-default-tool-tip" @click="switchChannel(channel)">
              <span>{{ channel.title }}</span>
              <span>{{ channel.email_account.username }}</span>
            </div>
          </div>
        </div>
        <div class="profile-view">
          <!-- <b-dropdown
          right
          menu-class="dropdown-menu-lg p-0"
          toggle-class="header-item noti-icon"
          variant="black"
        >
          <template v-slot:button-content>
            <i class="ri-notification-3-line"></i>
            <span class="noti-dot"></span>
          </template>
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">
                  {{ $t("navbar.dropdown.notification.text") }}
                </h6>
              </div>
              <div class="col-auto">
                <a href="#!" class="small">{{
                  $t("navbar.dropdown.notification.subtext")
                }}</a>
              </div>
            </div>
          </div>
          <simplebar style="max-height: 230px">
            <a href class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs mr-3">
                  <span
                    class="avatar-title bg-primary rounded-circle font-size-16"
                  >
                    <i class="ri-shopping-cart-line"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                    {{ $t("navbar.dropdown.notification.order.title") }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ $t("navbar.dropdown.notification.order.text") }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ $t("navbar.dropdown.notification.order.time") }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href class="text-reset notification-item">
              <div class="media">
                <img
                  src="@/assets/images/users/avatar-3.jpg"
                  class="mr-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                    {{ $t("navbar.dropdown.notification.james.title") }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ $t("navbar.dropdown.notification.james.text") }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ $t("navbar.dropdown.notification.james.time") }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs mr-3">
                  <span
                    class="avatar-title bg-success rounded-circle font-size-16"
                  >
                    <i class="ri-checkbox-circle-line"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                    {{ $t("navbar.dropdown.notification.item.title") }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ $t("navbar.dropdown.notification.item.text") }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ $t("navbar.dropdown.notification.item.time") }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href class="text-reset notification-item">
              <div class="media">
                <img
                  src="@/assets/images/users/avatar-4.jpg"
                  class="mr-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                    {{ $t("navbar.dropdown.notification.salena.title") }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ $t("navbar.dropdown.notification.salena.text") }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ $t("navbar.dropdown.notification.salena.time") }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </simplebar>
          <div class="p-2 border-top">
            <a
              class="btn btn-sm btn-link font-size-14 btn-block text-center"
              href="javascript:void(0)"
            >
              <i class="mdi mdi-arrow-right-circle mr-1"></i>
              {{ $t("navbar.dropdown.notification.button") }}
            </a>
          </div>
        </b-dropdown> -->

          <b-dropdown right variant="black" toggle-class="header-item" class="d-inline-block user-dropdown">
          
            <template v-slot:button-content>
              <img class="rounded-circle header-profile-user" v-if="user.photo === ''"
                src="@/assets/images/users/user.png" alt="Header Avatar" />

              <img class="rounded-circle header-profile-user" v-else-if="user.photo !== ''" :src="user.photo"
                alt="Header Avatar" />
              <span class="d-none d-xl-inline-block ml-1">{{
                  user.name
              }}</span>
              <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
            </template>
            <!-- item-->
            <router-link class="dropdown-item d-block" to="/email-account-settings">
              <i class="ri-settings-2-line align-middle mr-1"></i>
              {{ $t("navbar.dropdown.kevin.list.settings") }}
            </router-link>
            <div class="dropdown-divider"></div>
            <span class="dropdown-item text-danger" @click="logout" style="cursor:pointer">
              <i class="ri-shut-down-line align-middle mr-1 text-danger"></i>
              {{ $t("navbar.dropdown.kevin.list.logout") }}
            </span>
          </b-dropdown>
        </div>
      </div>

    </div>
  </div>
</template>